<template>
	
	<div :class="[ responsive ? 'dataTables-responsive' : '' ]">
		<table :class="['dataTables-strap table dT-striped dT-hover table-bordered m-0', 'dT-' + size ]">
			<!--caption>Showing 1 to 10 of 57 entries</caption-->
			<thead>
				<slot name="head" v-bind:head="columns">
					<tr>
						<template v-for="(item, key) in columns">
							<th scope="col" v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`">
								<div class="custom-control custom-checkbox mr-1">
									<input type="checkbox" class="custom-control-input" id="selectAllItems" v-model="item.checked" />
									<label class="custom-control-label" for="selectAllItems"></label>
								</div>
							</th>
							<th @click="sortBy(item.column)" :class="[ sortKey == item.column ? 'active' : '', item.class != null ? item.class : '' ]" v-else-if="item.enable" v-bind:key="key" scope="col">
								<div style=" color: #8c8c8c; font-size: 14px; "> <!--   style="display: flex;" -->
									<span class="c-pointer" v-html="item.title"></span>
									<div style=" display: inline-block;float: right;opacity: 0.66; " v-if="!['options', 'actions'].includes(item.column)">
										<i class="fa fa-sort-amount-asc c-pointer" v-if="sortOrders[item.column] > 0"></i>
										<i class="fa fa-sort-amount-desc c-pointer" v-else></i>
									</div>
								</div>
							</th>
						</template>
					</tr>
				</slot>
			</thead>
			<tbody>
				<slot name="body" v-bind:body="filteredData">
					<tr v-for="(entry, id) in filteredData" v-bind:key="id">
						<template v-for="(item, key) in columns">
							<td v-if="item.column == 'checkBox' && item.enable" v-bind:key="`k-${key}`">
								<div class="custom-control custom-checkbox mr-1">
									<input type="checkbox" class="custom-control-input" :id="`selectItems-${entry.id}`" v-model="itemIdsArray" :value="entry.id">
									<label class="custom-control-label" :for="`selectItems-${entry.id}`"></label>
								</div>
							</td>
							<td v-else-if="item.enable" v-bind:key="key">
								<!-- {{entry[key]}} -->
								<div>{{ entry[item.column] }}</div>
							</td>
						</template>
					</tr>
				</slot>
				<tr v-if="filteredData.length == 0">
					<td :colspan="columns.length"><center>No data available..</center></td>
					<!-- No matching records found   ( hadi f search ila makan walo ) -->
				</tr>
			</tbody>
			<slot name="footer">
				<tfoot v-if="footer">
					<tr>
						<template v-for="(item, key) in columns">
							<th scope="col" v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`">
								<div class="custom-control custom-checkbox mr-1">
									<input type="checkbox" class="custom-control-input" id="selectAllItemsFoot" v-model="item.checked" />
									<label class="custom-control-label" for="selectAllItemsFoot"></label>
								</div>
							</th>
							<th :class="[ sortKey == item.column ? 'active' : '', item.class != null ? item.class : '' ]" v-else-if="item.enable" v-bind:key="key" scope="col">
								<div style=" color: #8c8c8c; font-size: 14px; "> <!--   style="display: flex;" -->
									<span v-html="item.title"></span>
								</div>
							</th>
						</template>
					</tr>
				</tfoot>
			</slot>
		</table>
	</div>

</template>

<script>
	// register the grid component
	export default {
		name: 'DataTable',
		components: {
			
		},
		props: {
			dataItems	: Array,
			columns		: Array,
			checkBox	: Boolean,
			footer		: Boolean,
			responsive	: Boolean,
			size		: {type : String, default : 'md'}
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey		: '',
				sortOrders	: sortOrders,
				searchData	: '',
				itemIdsArray:[],
				
				selected	: [],
			}
		},
		
		methods: {
			
			sortBy (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
		},
		watch: {
			itemIdsArray (val) {
				let that = this
				that.$emit( 'actionClick', val, 'selectItem' );
			},
		},
		computed: {
			filteredData () {
				
				var sortKey = this.sortKey
				var filterKey = this.searchData && this.searchData.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var dataItems = this.dataItems
				if (filterKey) {
					dataItems = dataItems.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					dataItems = dataItems.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return dataItems
			}
		},
		
	}
	
</script>

<style lang="scss">
	.dataTables-strap {
		tbody {
			font-size: 12px;
			tr {
				td {
					vertical-align: inherit;
				}
			}
		}
		&.dT-sm {
			thead th, tfoot th {
				padding: 10px 10px;
				border-top-width: 1px;
				border-bottom-width: 1px;
			}
			tbody {
				td {
					padding: 6px 10px;
				}
			}
			
		}
		&.dT-striped tbody tr:nth-of-type(odd) {
			background-color: #fafafa;
		}
		
		&.dT-hover tbody tr:hover {
			color: #212529;
			background-color: #f5f5f5;
		}
		
	}
	.dataTables-responsive {
		overflow-y: auto;
		height: 460px;
	}
</style>
