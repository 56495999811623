<template>
	<div>
		<header class="select-list-actions">
			<div class="input-group">
				<input type="text" class="multiselect-search form-control border-0" v-model="filterText" placeholder="Search.." />
				<div class="input-group-append">
					<span class="input-group-text bg-white border-0"><i class="ti-search fs-12"></i></span>
				</div>
			</div>
		</header>
		<div class="select-list" v-if="filteredOptions.length > 0">
			<div v-if="selectAllCheck" class="button toggle-button" @click="selectAllEvent()">
				<i class="fa fa-check-square" v-if="selectAll"></i>
				<i class="fa fa-square-o" v-else></i>
				<span><b>Select all</b></span>
			</div>
			<div v-if="titleOption != '' && titleOption != null" class="option-drop">{{ titleOption }}</div>
			<div v-for="(option, i) in filteredOptions" v-bind:key="i">
				<div role="checkbox" :class="[ option.selected ? 'is-selected' : '', 'button', 'toggle-button']" @click="onToggle(option)" :title="option.value"><!-- isSelected[option.id] -->
					<!--  class="button toggle-button"  :class="[ isSelected[option.id] ? 'is-selected' : '', 'button', 'toggle-button']" -->
					<i class="fa fa-check-square" v-if="option.selected"></i><!-- isSelected[option.id] -->
					<i class="fa fa-square-o" v-else></i>
					<!--span v-html="option.value"></span-->
					<span :id="option.id" :inner-html.prop="option.value | maxLength"></span>
					<span class="pull-right" v-if="option.tag != ''">{{option.tag}}</span>
				</div>
			</div>
		</div>
		<div class="no-items" v-else>No items to display</div>
	</div>
</template>
<script>
	export default {
		components: {
			
		},
		data() {
			return { 
				filterText		: '',
				selectedOptions	: this.selected,
				selectAll 		: false,
			};
		},
		props: {
			'options'		: Array,
			'selected'		: Array,
			'defaultSelected': String,
			'selectAllCheck': Boolean,
			'titleOption'	: String
		},
		methods: {
			
			selectAllEvent() {
				let that = this
				
				that.selectAll = !that.selectAll
				that.$emit('change', 'selectall', that.selectAll);
				
			},
			
			onToggle(option) {
				// let $event = event
				let that = this
				if ( !that.selectedOptions.includes(option.id) ) {
					that.selectedOptions.push(option.id);
				} else {
					that.selectedOptions.splice(that.selectedOptions.indexOf(option.id), 1);
				}
				that.$emit('change', 'element', option.id );
				
			},
			
		},
		watch: {
			
			selected: function (val) {
				let that = this
				if( that.options.length == val.length ) {
					that.selectAll = true
				} else {
					that.selectAll = false
				}
			},
			
			defaultSelected(val) {
				if( val != 'all' ) {
					this.selectAll = false
				}
			}
			
		},
		computed: {
			filteredOptions() {
				let that = this
				var visibleOptions = [],
				filterText = that.filterText.trim().length > 0 ? that.filterText.toLowerCase() : null;
				
				if (filterText) {
					visibleOptions = that.options.filter((item) => {
						let option = that.isIsset(item.title) ? item.title : item.option
						return filterText.toLowerCase().split(' ').every(v => option.toLowerCase().includes(v));
					});
				} else {
					visibleOptions = that.options;
				}
				
				return visibleOptions.map(item => {
					let label		= '',
						option		= that.isIsset(item.title) ? item.title : item.option,
						optionId	= item.id,
						tag = typeof( item.tag ) != 'undefined' ? item.tag : '';
					if (filterText) {
						
						if( filterText.indexOf(' ') >= 0 ) {
							let arChar = filterText.split(' ')
							label = option;
							arChar.forEach( function( str ) {
								label = label.replace(str, '<b>'+ str +'</b>');
							} )
						} else {
							let searchStartIndex = option.toLowerCase().indexOf(filterText), 
							filterPart = option.substring(searchStartIndex, searchStartIndex + filterText.length);
							label = option.replace(filterPart, '<b>'+ filterPart +'</b>');
						}
						
					} else {
						label = option;
					}
					
					return {
						value	: label,
						id		: optionId,
						label	: label,
						selected: that.selectedOptions.includes(optionId), // 
						tag		: tag
					};
				});
			} 
		},
		
		filters: {
			maxLength: function (str) {
				if ( str != null && str != '' ) {
					return str.length > 55 ? str.substring(0, 55) + "...." : str
				} else {
					return ''
				}
			}
		},
		
	}
</script>
<style lang="scss">
	
</style>
