<template>
	<div class="autocomplete">
		<input type="text" @input="onChange" v-model.trim="search" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" :class="['form-control', invalid ? 'is-invalid' : '']" :placeholder="placeholder" :disabled="disabled" />
		
		<ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
			<li class="loading" v-if="isLoading">Loading results...</li>
			
			<!--li class="loading" v-else-if="results.length == 0">Nothing to display</li-->
			<li class="autocomplete-result" v-else-if="results.length == 0" @click="setResult(search)"><span><b>{{ search }}</b></span></li>
			
			<li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
				<span v-html="result.replace(search, '<b>' + search + '</b>')"></span>
				<!--span v-html="$options.filters.capitalize(result).replace(search, '<b>' + search + '</b>')"></span-->
			</li>
		</ul>
	</div>
</template>
<script>
	export default {
		name: 'AutoComplete',
		
		props: {
			items		: { type	: Array, 	required: false, default	: () => [] },
			isAsync		: { type	: Boolean, 	required: false, default	: false },
			placeholder	: { type	: String, 	required: false, default	: '' },
			defaultData	: { type	: String, 	required: false, default	: '' },
			disabled	: { type	: Boolean, 	required: false, default	: false },
			invalid		: { type	: Boolean, 	required: false, default	: false },
		},
		
		data() {
			return {
				isOpen	: false,
				results	: [],
				search	: '',
				isLoading: false,
				arrowCounter: 0,
			};
		},
		methods: {
			onChange() {
				// Let's warn the parent that a change was made
				
				// this.$emit('input', this.search);
				
				// Is the data given by an outside ajax request?
				if (this.isAsync) {
					this.isLoading = true;
				} else {
					// Let's  our flat array
					this.filterResults();
					this.isOpen = true;
				}
			},
			filterResults() {
				// first uncapitalize all the things
				this.results = this.items.filter((item) => {
					let result = item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
					return result
				});
			},
			setResult(result) {
				this.search = result;
				this.isOpen = false;
			},
			onArrowDown(/* evt */) {
				if (this.arrowCounter < this.results.length) {
					this.arrowCounter = this.arrowCounter + 1;
				}
			},
			onArrowUp() {
				if (this.arrowCounter > 0) {
					this.arrowCounter = this.arrowCounter -1;
				}
			},
			onEnter() {
				this.search = this.results[this.arrowCounter];
				this.isOpen = false;
				this.arrowCounter = -1;
			},
			handleClickOutside(evt) {
				if (!this.$el.contains(evt.target)) {
					this.isOpen = false;
					this.arrowCounter = -1;
				}
			}
		},
		watch: {
			items: function (val, oldValue) {
				// actually compare them
				if (val.length !== oldValue.length) {
					this.results = val;
					this.isLoading = false;
				}
			},
			search: function (val) {
				this.$emit('input', val);
			},
			defaultData: function (val) {
				this.search = val
			},
		},
		mounted() {
			document.addEventListener('click', this.handleClickOutside)
			this.search = this.defaultData
		},
		destroyed() {
			document.removeEventListener('click', this.handleClickOutside)
		},
		
		filters: {
			
		},
		
	};
</script>
<style lang="scss" scope>
	.autocomplete {
		position: relative;
	}
	
	.autocomplete-results {
		padding: 8px 0px;
		margin: 0;
		border: 1px solid #eeeeee;
		max-height: 120px;
		overflow: auto;
		position: absolute;
		width: 100%;
		z-index: 2;
		background-color: #fff;
		box-shadow: 0px 1px 3px #eee;
	}
	
	.loading {
		font-size: 12px;
		text-align: center;
	}
	.autocomplete-result, .loading {
		padding: 4px 12px;
	}
	.autocomplete-result {
		list-style: none;
		text-align: left;
		cursor: pointer;
	}
	
	.autocomplete-result:hover {
		background-color: #f8f9fa;
		color: #152c5b;
	}
</style>