<template>
	<div :class="['dropdown dropdown-md', collapse ? 'show' : '']">
		<div class="dropdown-toggle no-after peers fxw-nw ai-c lh-1" ref="dropdown" @click="EventsCollapse()">
			<slot name="label">
				<div class="peer c-pointer">
					<span class="fsz-sm c-grey-900">{{ label }} <i :class="[collapse ? 'ti-angle-up' : 'ti-angle-down']" style="font-size: 10px;"></i></span>
				</div>
			</slot>
		</div>
		
		<ul :class="['dropdown-menu fsz-sm', collapse ? 'show' : '']">
			<slot name="options">
				<li v-for="(item, id) in items" v-bind:key="`li-${id}`">
					<a href="javascript:void(0);" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
						<i :class="[ item.icon == null ? '' : item.icon, 'mR-10']"></i>
						<span>{{ item.title }}</span>
					</a>
				</li>
			</slot>
			<!--li role="separator" class="divider"></li-->
		</ul>
	</div>
</template>
<script>
	export default {
		name: 'DropDown',
		components: {
			
		},
		props: {
			label	: String,
			items	: Array
		},
		data() {
			return {
				collapse : false
			}
		},
		
		methods: {
			
			EventsCollapse () {
				let that = this
				document.addEventListener('click', this.documentClick);
				that.collapse = !that.collapse
				that.$emit('toggle')
			},
			documentClick(e) {
				let that = this, el = this.$refs.dropdown, target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						that.collapse = false
						that.$emit('toggle')
						document.removeEventListener('click', that.documentClick);
					}
				}
			},
			
		},
		watch: {
			
		},
		computed: {
			
		},
		
		
	}
	
</script>

<style lang="scss">
	.dropdown-md {
		
		ul.dropdown-menu {
			left: auto;
			right: 0px;
			/* li {
				> a {
					i {
						vertical-align: text-bottom;
					}
				}
			} */
		}
		
		.dropdown-menu {
			display: block;
			margin: 0;
			transform-origin: top right;
			transform: scale(0, 0);
			transition: transform 0.15s ease-out;
		}

		.show {
			&.dropdown-menu {
				transform: scale(1, 1);
			}
		}
		
	}
</style>
