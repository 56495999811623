<template>
	<div class="m-d expand-list">
		<div :class="['expand-header c-pointer', expand ? 'active' : '']" @click="expand = !expand">
			<slot name="header">
				<span v-html="title" class="title"></span>
				<span class="open-close-icon">
					<i :class="['fa up',  icon == 'chevron' ? 'fa-chevron-down' : 'fa-plus']"></i>
					<i :class="['fa down',  icon == 'chevron' ? 'fa-chevron-down' : 'fa-minus']"></i>
				</span>
			</slot>
		</div>
		<div class="content">
			<slot name="content">
				<div v-html="content"></div>
			</slot>
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'ExpandContent',
		data() {
			return {
				expand: false
			}
		},
		props: {
			content	: { type: String, default: '' },
			title	: { type: String, default: 'Expand' },
			open	: { type: Boolean, default: false },
			icon	: { type: String, default: '' }
		},
		methods: {
			
		},
		components: {
			
		},
		computed : {
			
		},
		mounted() {
			this.expand = this.open
		},
		watch: {
			open(val) {
				this.expand = val
			}
		}
	}
</script>
<style lang="scss" scope>
	.m-d {
		&.expand-list > .expand-header {
			padding: 10px 12px;
			position: relative;
			margin-top: 5px;
			border: 1px solid #ddd;
			
			border-radius: 4px;
			.title {
				font-weight: 600;
			}
			&:hover {
				background-color: #fbfbfb;
			}
		}
		
		.content {
			height: auto;
			max-height: 0;
			overflow: hidden;
			transition: all 200ms ease-in-out 0ms;
		}
		
		.active ~ .content {
			max-height: 900px;
			transition: all 400ms ease-in-out 0ms;
			margin-bottom: 5px;
			padding: 15px;
			
			border: 1px solid #ddd;
			border-top: 0px;
			/* border-top: 1px solid #ddd; */
			overflow-y: auto;
		}
		
		.open-close-icon {
			display: inline-block;
			position: absolute;
			right: 25px;
			transform: translatey(3px);
		}
		.open-close-icon i {
			position: absolute;
			left: 0;
		}
		.open-close-icon .down {
			transform:rotate(-90deg);
			transition: transform 150ms ease-in-out 0ms;
		}
		.active {
			.open-close-icon .down {
				transform: rotate(0deg);
				transition: transform 150ms ease-in-out 0ms;
			}
		}
		.open-close-icon .up {
			opacity: 1;
			transform:rotate(-90deg);
			transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms;
		}
		.active {
			.open-close-icon .up {
				opacity: 0;
				transform: rotate(0deg);
				transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms; 
			}
		}
	}
</style>
