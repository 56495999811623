<template>
	<div>
		<date-range-picker ref="picker" :showWeekNumbers="true" label="Custom Range"
		:showDropdowns="true" v-model="dateRange" @update="updateDateRange" opens="left" singleDatePicker="range">
			<!--template v-slot:input="picker" style="min-width: 350px;">
				{{ picker.startDate | date }} - {{ picker.endDate | date }}
			</template-->
		</date-range-picker>
	</div>
</template>
<script>
	// https://innologica.github.io/vue2-daterange-picker/#usage
	import DateRangePicker from '@/plugins/vue2-daterange-picker/src'
	//you need to import the CSS manually
	// import '@/plugins/vue2-daterange-picker/dist/vue2-daterange-picker.css'
	
	export default {
		name: 'DatePicker',
		components: {
			DateRangePicker
		},
		props: {
			
		},
		data() {
			return {
				dateRange	 : {},
			}
		},
		computed : {
			
		},
		methods: {
			updateDateRange(values) {
				this.dateRange.from    = values.startDate == null ? '' : values.startDate
				this.dateRange.to 	   = values.endDate == null ? '' : values.endDate
				
				let dateRange = this.dateRange.from != '' ? { 'from': values.startDate, 'to': values.endDate } : ''
				this.$emit('input', dateRange)
			},
			
		},
		filters: {
			
		}
	}
</script>
<style lang="scss">
	
</style>
