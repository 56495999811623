<template>
	<div class="layer w-100 mB-15">
		<h5 class="mB-5">{{ Title }}</h5>
		<small class="fw-600 c-grey-700">{{ subTitle }}</small>
		<span class="pull-right c-grey-600 fsz-sm">{{ percent }}%</span>
		<div class="progress mT-10">
			<div :class="['progress-bar', bgColor]" role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100" :style="{width: percent + '%'}">
				<span class="sr-only">{{ percent }}% Complete</span>
			</div>
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'ProgressBar',
		props: {
			percent	: {},
			bgColor	: {},
			subTitle: {},
			Title	: {}
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	
</style>
