<template>
	<main>
		<!-- The Modal -->
		<boardal v-if="modal.isOpen" :has-mask="modal.hasMask" :can-click-mask="modal.canClickMask" :has-x="modal.hasX" @toggle="toggleModal">
			<article>
				<section class="row m-auto">
					<div class="col-8 mt-auto">
						<h3>Hi there, Welcome!</h3>
						<p>We are delighted to have you among us. On behalf of all the members and the teams, we would like to extend our warmest welcome and good wishes!</p>
						<p>This step-by-step guide helps you to begin in our platform.</p>
					</div>
					<div class="col-4 text-center">
						<span style="font-size: 114px;">👋</span>
					</div>
				</section>
				<section class="row m-auto">
					<div class="col-8 mt-auto">
						<h3>Step 1: Add your list!</h3>
						<p>Now you can easily add your lists by clicking to <b>New List</b> in Lists Management.</p>
						<p>You can add your list of leads and mapping your fields or add your emails / phones lists.</p>
					</div>
					<div class="col-4 text-center">
						<span style="font-size: 114px;"><i class="c-orange-500 ti-layout-list-thumb"></i></span>
					</div>
				</section>
				<section class="row m-auto">
					<div class="col-8 mt-auto">
						<h3>Step 2: Manage your leads & Statuses!</h3>
						<p>By clicking to <b>My Leads</b> you can manage your leads.</p>
						<p>Go to <b>My Settings</b> to manage your leads statuses as your workflow.</p>
					</div>
					<div class="col-4 text-center">
						<span style="font-size: 114px;"><i class="c-purple-500 fa fa-filter"></i></span>
					</div>
				</section>
				<section class="row m-auto">
					<div class="col-8 mt-auto">
						<h3>Step 3: Campaigns & Landing pages</h3>
						<p>Creating your email campaigns is easier in our platform. You can mix your lists and choose one of our senders.</p>
						<p>Choose your landing page from gallery or build one in template builder.</p>
						<!--p><button class="accent" @click="skip(1)">Let's Get Started</button></p-->
					</div>
					<div class="col-4 text-center">
						<span style="font-size: 114px;"><i class="c-teal-500 fa fa-bullhorn"></i></span>
					</div>
				</section>
			</article>
			<footer>
				<div class="forward-actions">
					<button class="primary next" :disabled="isLastStep" v-show="!isLastStep" @click="skip(1)"><i class="fa fa-fw fa-lg fa-arrow-right"></i></button>
					<button class="btn btn-primary accent save" :disabled="!isLastStep" v-show="isLastStep" @click="toggleModal()"><i class="fa fa-fw fa-lg fa-check"></i></button>
				</div>
				<div class="step-dots">
					<div class="step-dot" v-for="(n, id) in max" v-bind:key="`${id}`" :class="{active: n == step}" @click="goToStep(n)"></div>
				</div>
				<div class="back-actions">
					<button class="secondary cancel prev" :disabled="isFirstStep" @click="skip(-1)" v-if="!isFirstStep"><!-- xv-show="!isFirstStep" -->
						<i class="fa fa-fw fa-lg fa-arrow-left"></i>
					</button>
				</div>
			</footer>
		</boardal>
	</main>
</template>
<script>
	import boardal from './Boardal'
	export default {
		data() {
			return {
				modal: {
					isOpen	: false,
					hasMask	: true,
					canClickMask: false,
					hasX	: false
				},
				step	: 1,
				max		: 1,
			}
		},
		computed: {
			isFirstStep: function(){
				return (this.step === 1)
			},
			isLastStep: function(){
				return (this.step === this.max)
			},
		},
		watch: {
			
		},
		methods: {
			toggleModal: function(step) {
				step = step || 1
				this.modal.isOpen = !this.modal.isOpen
				if(this.modal.isOpen) {
					let self = this
					setTimeout(function(){
						self.$sections = self.$el.querySelectorAll('section')
						self.max = self.$sections.length
						self.goToStep(step)
					}, 1)
				}
			},
			setCssVars () {
				this.$el.style.setProperty('--x', (((this.step * 100) - 100) * -1) + '%')
				this.$el.style.setProperty('--y', (((this.step * 100) - 100) * 0) + '%')
				this.$el.style.setProperty('--axis', 'row')
				this.$el.style.setProperty('--axis-reverse', 'row-reverse')
				this.$el.style.setProperty('--cross', 'column')
				this.$el.style.setProperty('--cross-reverse', 'column-reverse')
			},
			goToStep (step){
				this.step = step > this.max ? this.max : step < 1 ? 1 : step
				this.currentSection = this.$sections[this.step-1]
				this.$sections.forEach(function(section){
					section.classList.remove('current')
				})
				this.currentSection.classList.add('current')
				this.currentSection.scrollTop = 0
				this.setCssVars()
			},
			skip: function(step){
				this.step+=step
				this.goToStep(this.step)
			},
			reset: function(){
				this.goToStep(1)
			},
		},
		mounted() {
			if( this.$store.state.onBoard ) {
				this.$store.state.onBoard = false
				this.toggleModal()
			}
		},
		components: {
			boardal
		},
	}
</script>

<style lang="scss" scoped>
	
	:root {
		--accent: #8fd1f2;
	}
	
	.boardal-enter-active, .boardal-leave-active {
		transition: opacity 0.25s;
	}
	.boardal-enter, .boardal-leave-to {
		opacity: 0;
	}
	
	article {
		flex: 1 1 100%;
		height: 100%;
		display: flex;
		flex-direction: var(--axis, row);
		overflow: hidden;
	}

	section {
		width: 100%;
		visibility: hidden;
		flex: 0 0 100%;
		padding: 2em;
		overflow: auto;
		will-change: transform;
		transform: translate(var(--x, 0%), var(--y, 0%));
		transition: transform 300ms ease-out;
		position: relative;
		
		h2, h3, h4 {
			margin-top: 0;
		}
		
		&.current {
			visibility: visible;
		}
	}
	
	footer {
		position: relative;
		text-align: right;
		display: flex;
		flex-direction: var(--axis-reverse, row-reverse);
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
		background: rgba(0, 0, 0, 0.05);
		
		&:not(:empty) {
			padding: 1.5em;
		}
	}
	
	.step-dots {
		display: flex;
		flex-direction: var(--axis, row);
	}

	.step-dot {
		cursor: pointer;
		width: 1em;
		height: 1em;
		margin: 0.5ch;
		border-radius: 1em;
		background: currentColor;
		opacity: 0.2;
		transition: transform 100ms ease-out, opacity 150ms linear;
		
		&.active {
			opacity: 0.7;
			box-shadow: 0 0 1em -0.25em;
		}
		&:hover {
			transform: scale(1.2);
		}
	}
	
	.forward-actions, .back-actions {
		flex: 1;
		display: flex;
		flex-direction: var(--axis, row);
	}

	.forward-actions {
		justify-content: flex-end;
	}

	.back-actions {
		justify-content: flex-start;
	}
	
	button {
		&:active {
			transform: scale(0.95);
		}
		&:focus {
			border-color: var(--accent);
			box-shadow: 0 0 1em 0 var(--accent);
		}
		&[disabled] {
			opacity: 0.2;
			cursor: not-allowed;
		}
		&.primary {
			border-color: transparent;
			background: transparent;
			font-weight: bold;
		}
		&.primary:not([disabled]) {
			color: var(--accent);
		}
		
		&.secondary {
			border-color: transparent;
			background: transparent;
		}
		&.secondary:not([disabled]) {
			color: rgba(0, 0, 0, 0.4);
		}
		&.cancel:not([disabled]) {
			color: var(--accent);
		}
	}
	
</style>
