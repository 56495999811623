<template>
	<div :class="[ 'dropdown-options', maxWidth ? 'w-100' : '' ]" ref="dropOptions">
		<button v-on:click="ToggleDropdown()" :class="['dropdown-btn', maxWidth ? 'w-100' : '', radius]">
			<!--slot name="label">
				<span class="fsz-sm c-grey-900">{{ label }} <i :class="[dropdown ? 'ti-angle-up' : 'ti-angle-down']" style="font-size: 10px;"></i></span>
			</slot-->
			<slot name="label">
				<div class="fsz-sm c-grey-800 flex-container space-between">
					<slot name="title">
						<div class="d-flex">
							<i v-if="icon != ''" :class="[icon, 'my-auto mR-10']"></i> <span v-html="max_length(SelectedValue, maxLength)"></span>
						</div>
					</slot>
					<i :class="[dropdown ? 'ti-angle-up' : 'ti-angle-down', 'fs-8 mT-8']"></i>
				</div>
			</slot>
		</button>
		<transition name="fade">
			<div :class="['list-options', right ? 'right' : '']" v-if="dropdown">
				<!--div class="border-bottom">
					<input class="form-control border-0" placeholder="Search.." />
				</div-->
				
				<div class="input-group border-bottom">
					<input class="form-control border-0" placeholder="Search.." v-model.trim="FilterText" />
					<div class="input-group-append">
						<span class="input-group-text bg-white border-0"><i class="ti-search fs-12"></i></span>
					</div>
				</div>
				
				<ul>
					<slot name="options" v-bind:options="FilteredOptions">
						<li v-for="(item, id) in FilteredOptions" v-bind:key="`li-${id}`" :class="[SelectedId == id ? 'active' : '']" @click="onChange(item, id)">
							<a href="javascript:void(0);" class="d-b td-n pY-5 pX-5 bgcH-grey-100 c-grey-700 position-relative">
								<i :class="[ item.icon == null ? '' : item.icon, 'mR-10']"></i>
								<span v-html="max_length(item.title, maxLength)"></span>
								<span class="badge badge-primary text-white fs-9 position-absolute mY-5 text-capitalize" 
								style=" right: 4px; " v-if="isIsset(item.tag)">{{ item.tag }}</span>
							</a>
						</li>
						<li v-if="FilteredOptions.length == 0" class="d-b td-n pY-10 pX-5 bgcH-grey-100 c-grey-700 text-center">
							No items to display
						</li>
					</slot>
					<!--li role="separator" class="divider"></li-->
				</ul>
			</div>
		</transition>
	</div>
</template>
<script>
	export default {
		name: 'DropOptions',
		components: {
			
		},
		props: {
			icon		: { type: String,  default: '' },
			label   	: { type: String,  default: '' },
			options 	: { type: Array },
			right   	: { type: Boolean, default: false },
			value   	: { },
			maxWidth	: { type: Boolean, default: false },
			radius		: { type: String,  default: 'rounded' },
			maxLength	: { type: [Number, String],  default: 0 },
		},
		data() {
			return {
				dropdown	 : false,
				SelectedValue: 'dropdown options',
				FilterText	 : '',
				SelectedId	 : 'NaN'
			}
		},
		watch: {
			value(val) {
				let that	  = this
				that.dropdown = false
				if( val == '' ) {
					that.SelectedId 	= 'NaN'
					that.SelectedValue 	= that.label
				} else {
					that.options.forEach( function(item, id) {
						if( val == item.option ) {
							that.SelectedId 	= id.toString()
							that.SelectedValue 	= item.title
						}
					} )
				}
			},
			options: {
				handler(val) {
					let that = this
					val.forEach( function(element, id) {
						if( that.value == element.option ) {
							that.SelectedId 	= id.toString()
							that.SelectedValue 	= element.title
						}
					} )
				},
				deep:true
			}
		},
		mounted () {
			this.SelectedValue = this.label
		},
		methods: {
			onChange(element, id) {
				let that = this
				that.dropdown 		= false
				
				if( that.SelectedId.toString() != id.toString() ) {
					that.FilterText 	= ''
					that.SelectedValue 	= element.label
					that.SelectedId		= id.toString()
					that.$emit('input', element.option)
					that.$emit('change', element)
				}
			},
			ToggleDropdown() {
				document.addEventListener('click', this.documentClick);
				this.dropdown = !this.dropdown
				// this.dropdown = this.dropdown === true ? false : true
			},
			documentClick(e) {
				let that = this, el = this.$refs.dropOptions, target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						that.dropdown = false
						document.removeEventListener('click', that.documentClick);
					}
				}
			}
		},
		computed : {
			FilteredOptions() {
				let that = this
				var visibleOptions = [], FilterText = this.FilterText.trim().length > 0 ? this.FilterText.toLowerCase() : null;
				
				if (FilterText) {
					visibleOptions = this.options.filter((item) => {
						return FilterText.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v));
					});
				} else {
					visibleOptions = this.options;
				}
				
				return visibleOptions.map(item => {
					let label = '', title = item.title, option = item.option, tag = that.isIsset(item.tag) ? item.tag : '';
					if (FilterText) {
						
						if( FilterText.indexOf(' ') >= 0 ) {
							let arChar = FilterText.split(' ')
							label = title;
							arChar.forEach( function( str ) {
								label = label.replace(str, '<b>'+ str +'</b>');
							} )
						} else {
							let searchStartIndex = title.toLowerCase().indexOf(FilterText), 
							filterPart = title.substring(searchStartIndex, searchStartIndex + FilterText.length);
							label = title.replace(filterPart, '<b>'+ filterPart +'</b>');
						}
						
					} else {
						label = title;
					}
					return {
						option 	: option.toString(),
						title	: label,
						tag		: tag,
						label	: title
					};
				});
			}
		},
	}
</script>
<style lang="scss">
	
	.dropdown-options {
		
		display: inline-block;
		position: relative;
		
		/* width: 100%; */
		top: 2px;
		
		.dropdown-btn {
			min-width: 14em;
			height: 40px;
			outline-style: none;
			text-align: left;
			padding-left: 10px;
			padding-right: 15px;
			background-color: #fff;
			border: 1px solid #ced4da;
			width: 100%;
		}
		
		.list-options {
			ul {
				list-style: none;
				padding: 0;
				max-height: 15em;
				overflow-y: auto;
				li {
					a:hover {
						background-color: #eee !important;
						color: #343a40 !important;
						span {
							color: #343a40;
						}
					}
					a >span {
						color: #72777a;
					}
				}
				li.active {
					a {
						background-color: #eee !important;
						color: #343a40 !important;
						span {
							color: #343a40;
						}
					}
				}
			}
			
			background: #fff !important;
			-webkit-box-shadow: 0px 0px 5px 0px #eee;
			-moz-box-shadow: 0px 0px 5px 0px #eee;
			box-shadow: 0px 0px 5px 0px #eee;
			
			position: absolute;
			
			z-index: 9;
			float: left;
			min-width: 10rem;
			width: 100%;
			padding: 0px;
			margin-top: 2px;
			font-size: 0.875rem;
			color: #212529;
			text-align: left;
			list-style: none;
			
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0px;
			
			&.right {
				right: 0;
			}
			
		}
	}
	
	.fade-enter-active, .fade-leave-active {
		transition: opacity .8s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
