<template>
	<div class="ql-editor-md">
		<!-- Two-way Data-Binding -->
		<quill-editor
			ref="myQuillEditor"
			v-model="content"
			:options="editorOption"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"
			@change="onEditorChange($event)"
			:content="content"
		/>
		
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import "quill-emoji/dist/quill-emoji.css";
	
	import { quillEditor, Quill } from 'vue-quill-editor'
	
	import ImageResize from 'quill-image-resize-vue';
	Quill.register("modules/imageResize", ImageResize);
	
	import quillEmoji from 'quill-emoji';
	Quill.register("modules/emoji", quillEmoji);
	
	export default {
		name: 'QuillEditor',
		props: {
			value: {}
		},
		components: {
			quillEditor
		},
		data () {
			return {
				content		: '',
				editorOption: {
					// Some Quill options...
					placeholder: 'Insert your email here ...',
					modules: {
						// imageDrop: true,
						imageResize: {},
						/* toolbar : , */
						toolbar : {
							container: [
								
								['bold', 'italic', 'underline', 'strike'],        // toggled buttons
								['blockquote', 'code-block'],
								// [{ 'header': 1 }, { 'header': 2 }],               // custom button values
								[{ 'list': 'ordered'}, { 'list': 'bullet' }],
								[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
								[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
								[{ 'direction': 'rtl' }],                         // text direction
								[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
								[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
								[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
								[{ 'font': [] }],
								[{ 'align': [] }],
								['clean'],                                         // remove formatting button
								['link', 'image'],
								['emoji'],
								
							],
							handlers: {'image': function() { document.getElementById('getImageFile').click(); }, 'emoji': function() {}}
							// handlers: {'emoji': function() {}}
						},
						"emoji-toolbar"	 : true,
						"emoji-shortname": true,
						// "emoji-textarea" : true,
					},
					theme: 'snow'
				}
			}
		},
		methods: {
			onEditorBlur(quill) {
				this.$emit('onEditorBlur', quill)
			},
			onEditorFocus(quill) {
				this.$emit('onEditorFocus', quill)
			},
			onEditorReady(quill) {
				this.$emit('onEditorReady', quill)
			},
			onEditorChange({ quill, html, text }) {
				this.content = html
				this.$emit('onEditorReady', quill, html, text)
				this.$emit('input', html)
			}
		},
		watch: {
			value(val) {
				this.content = val
			}
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill
			}
		},
		mounted() {
			this.content = this.value
		}
		
	}
</script>

<style lang="scss" scope>
	.ql-editor-md {
		.ql-editor {
			background-color: #fff;
			height: 16em;
			max-height: 16em;
		}
	}
</style>