<template>
	<div class="form-group label-weight">
		<div class="row mb-3">
			<div class="col-4"><b>Your File Fields</b></div>
			<div class="col-2 my-auto text-center"></div>
			<div class="col-6 pl-0"><b>Mapping With System</b><!-- Field --></div>
		</div>
		<div class="row pb-2" v-for="(item, key) in MappingFields" v-bind:key="`field-${key}`">
			<div class="col-12" v-if="item.field == 'campaign_name'"><div class="border-top mb-2"></div></div>
			<div class="col-4">
				Map <b> {{ item.label }} </b> with
			</div>
			<div class="col-2 my-auto">
				<i class="fa fa-arrows-h fa-lg-md"></i>
				<!--i class="fa fa-long-arrow-right"></i-->
			</div>
			<div :class="[item.selected == 'custom_field' ? 'col-3' : 'col-6', 'pl-0']">
				<select class="form-control mapping-select" @change="SelectField(item)" v-model="item.selected">
					<option value=""> -- Select Field -- </option>
					<option v-for="(option, id) in fieldsArray" 
					v-bind:key="`option-${id}`" 
					v-text="option.title" :value="option.field" 
					:disabled="option.selected && option.field != 'custom_field'"></option>
				</select>
			</div>
			<div class="col-3 pl-0" v-if="item.selected == 'custom_field'">
				<!--input type="text" class="form-control" placeholder="title field" v-model="item.custom" /-->
				<auto-complete :items="CustomFields" placeholder="Custom Field" @input="customInput(item)" v-model="item.custom" :defaultData="item.custom"></auto-complete>
			</div>
		</div>
	</div>
</template>
<script>
	import { AutoComplete } from '@/components'
	export default {
		name: 'MappingFields',
		model: {
			prop: 'FieldsMapped', event: 'update'
		},
		props: {
			FieldsMapped : { type: Array, default: () => [] }
		},
		components: {
			AutoComplete
		},
		data() {
			return {
				MappingFields 	: [],
				CustomFields  	: [],
				fieldsArray		: [
					{ field: 'full_name',  	title: 'Full name', 	selected: false	},
					{ field: 'first_name', 	title: 'First name', 	selected: false	},
					{ field: 'last_name',  	title: 'Last name', 	selected: false	},
					{ field: 'email',     	title: 'Email Address', selected: false	},
					{ field: 'phone',     	title: 'Phone Number', 	selected: false	},
					{ field: 'generated_at',title: 'Generated at',	selected: false },
					{ field: "custom_field",title: "Custom Field", 	selected: false },
				],
				UpdateFields	: true
			}
		},
		watch: {
			FieldsMapped: {
				handler(val) {
					let that = this
					if( that.UpdateFields ) {
						that.MappingFields = val
						that.AutoMapping()
						that.UpdateFields = false
					}
				},
				deep: true
			}
		},
		mounted() {
			this.GetConfig()
		},
		methods: {
			
			customInput(  ) { // item
				
			},
			
			GetConfig() {
				
				let that	= this
				let request = { url: '/fields', method: 'GET', params: {} }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					
					let CustomFields	= response.data.custom_fields
					that.CustomFields	= CustomFields
					that.$store.state.loading = false
					
				}).catch(( {response} ) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			SelectField(element) {
				
				let that = this
				if( element.oldValue != '' ) {
					that.fieldsArray.forEach( function(item) {
						if ( item.field == element.oldValue ) {
							item.selected = false
							item.oldValue = ''
						}
					} )
				}
				that.fieldsArray.forEach( function(item) {
					if( element.selected == item.field ) {
						item.selected = true
						element.oldValue = element.selected
					}
				} )
				that.$emit('update', that.MappingFields)
				
			},
			
			AutoMapping() {
				
				let that	= this
				let fields	= []
				that.MappingFields.forEach( function(col) {
					fields.push(col.selected)
				} )
				// .push({ field: item, label: columns[item], selected: '', oldValue: '', custom: item })
				
				that.fieldsArray.forEach( function(col) {
					col.selected = false
				} )
				
				that.fieldsArray.forEach( function(col) {
					if( fields.includes(col.field) ) {
						col.selected = true
					}
				} )
				
            },
			
		}
	}
</script>
<style lang="scss">
	
</style>
