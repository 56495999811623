<template>
	<div>
		<transition name="boardal">
			<div class="boardal">
				<div class="boardal__mask" v-if="hasMask" @click="clickMask"></div>
				<div class="boardal__wrapper">
					<slot></slot>
					<div class="boardal__x" v-if="hasX" @click="clickX">&times;</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
	export default {
		data() {
			return {
			
				
			}
		},
		props: [
    'hasX',
    'hasMask',
    'canClickMask'
  ],
  methods: {
    clickX: function(){
      this.$emit('toggle')
    },
    clickMask: function(){
      if(this.canClickMask) {
        this.$emit('toggle')
      }
    }
	}
	}
</script>

<style lang="scss" scoped>

.boardal {
	z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

.boardal__mask {
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.boardal__wrapper {
  position: relative;
  width: 65vw;
  max-width: 60em;
  min-width: 30em;
  max-height: 65vh;
  height: 30em;
  background: #fff;
  color: #333;
  display: flex;
  flex-direction: var(--cross, column);
  border-radius: 0.2em;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1em 2em -1em;
}
.boardal__x {
  cursor: pointer;
  font-size: 2em;
  line-height: 0.5;
  opacity: 0.15;
}
.boardal__x:hover {
  opacity: 0.65;
}
}

</style>
