<template>
	<div :class="['layers bd bgc-white p-20', Cls]">
		<div class="layer w-100 mB-10">
			<h6 class="lh-1">{{ Title }}</h6>
		</div>
		<div class="layer w-100">
			<div class="peers ai-sb fxw-nw">
				<div class="peer peer-greed m-auto">
					<!--span id="sparklinedash"></span-->
					<i :class="[Icon, Color, 'fs-22']"></i>
				</div>
				<div class="peer">
					<span :class="['d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15', Color, bgColor]">{{ Value }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'masonry-item',
		props: {
			Title	: {},
			Icon	: {},
			Value	: {},
			Color	: {},
			bgColor	: {},
			Cls		: {},
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed : {
			LoadingAction() {
				return this.$store.state.loading
			}
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	
</style>
