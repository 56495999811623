<template>
	<div class="custom-table">
		<!--div :class="[ responsive ? 'dataTables-responsive' : '' ]"-->
		<div class="tbl-header pR-8"><!--  :class="['tbl-header', filteredData.length > 10 ? 'pR-8':'']" -->
			<table :class="['dataTables-grid table m-0', 'dT-' + size ]">
			<!--table :class="['dataTables-grid table dT-striped dT-hover table-bordered m-0', 'dT-' + size ]"-->
				<!--caption>Showing 1 to 10 of 57 entries</caption-->
				<thead>
					<slot name="head" v-bind:head="columns">
						<tr>
							<template v-for="(item, key) in columns">
								<th scope="col" v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`" :style="{width: item.width}">
									<!--div class="custom-control custom-checkbox mr-1">
										<input type="checkbox" class="custom-control-input" id="selectAllItems" v-model="item.checked" />
										<label class="custom-control-label" for="selectAllItems"></label>
									</div-->
									<check-box id="selectAllItems" v-model="item.checked"></check-box>
								</th>
								<th scope="col" v-else-if="item.enable && item.column == 'options'" v-bind:key="`k-${key}`" :class="[ item.class != null ? item.class : '' ]" :style="{width: item.width}">
									<div style=" color: #8c8c8c; font-size: 14px; ">
										<span class="c-pointer">{{ item.title }}</span>
									</div>
								</th>
								<th @click="sortBy(item.column)" :class="[ sortKey == item.column ? 'active' : '', item.class != null ? item.class : '' ]" v-else-if="item.enable" v-bind:key="key" scope="col" :style="{width: item.width}" :title="item.title">
									<div style=" color: #8c8c8c; font-size: 14px; "> <!-- style="display: flex;" -->
										<span class="c-pointer">{{ max_length(item.title, thLength) }}</span>
										<div style=" display: inline-block;float: right;opacity: 0.66; ">
											<i class="fa fa-sort-amount-asc c-pointer" v-if="sortOrders[item.column] > 0"></i>
											<i class="fa fa-sort-amount-desc c-pointer" v-else></i>
										</div>
									</div>
								</th>
							</template>
						</tr>
					</slot>
				</thead>
			</table>
		</div>
		<div :class="['tbl-content', 'content-' + id]">
			<table :class="['dataTables-grid table dT-striped dT-hover table-bordered m-0', 'dT-' + size ]">
				<tbody>
					<slot name="body" v-bind:body="filteredData">
						<tr v-for="(entry, id) in filteredData" v-bind:key="id">
							<template v-for="(item, key) in columns">
								<td v-if="item.column == 'checkBox' && item.enable" v-bind:key="`k-${key}`">
									<!--div class="custom-control custom-checkbox mr-1">
										<input type="checkbox" class="custom-control-input" :id="`selectItems-${entry.id}`" v-model="itemIdsArray" :value="entry.id">
										<label class="custom-control-label" :for="`selectItems-${entry.id}`"></label>
									</div-->
									<check-box :id="`selectItems-${entry.id}`" v-model="itemIdsArray" :val="entry.id"></check-box>
								</td>
								<td v-else-if="item.enable" v-bind:key="key">
									<!-- {{entry[key]}} -->
									<div>{{ entry[item.column] }}</div>
								</td>
							</template>
						</tr>
					</slot>
					<tr v-if="filteredData.length == 0">
						<td :colspan="columns.length" class="pT-10 pB-10"><center>No data available..</center></td>
						<!-- No matching records found   ( hadi f search ila makan walo ) -->
					</tr>
				</tbody>
				<slot name="footer">
					<tfoot v-if="footer">
						<tr>
							<template v-for="(item, key) in columns">
								<th scope="col" v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`">
									<!--div class="custom-control custom-checkbox mr-1">
										<input type="checkbox" class="custom-control-input" id="selectAllItemsFoot" v-model="item.checked" />
										<label class="custom-control-label" for="selectAllItemsFoot"></label>
									</div-->
									<check-box id="selectAllItemsFoot" v-model="item.checked"></check-box>
								</th>
								<th :class="{ active: sortKey == item.column }" v-else-if="item.enable" v-bind:key="key" scope="col">
									<div style=" color: #8c8c8c; font-size: 14px; "> <!--   style="display: flex;" -->
										<span>{{ item.title }}</span>
									</div>
								</th>
							</template>
						</tr>
					</tfoot>
				</slot>
			</table>
		</div>
	</div>
</template>

<script>
	// register the grid component
	import CheckBox from "@/components/Common/CheckBox";
	export default {
		name: 'DataTable',
		components: {
			CheckBox
		},
		props: {
			dataItems	: Array,
			columns		: Array,
			// checkBox	: Boolean,
			footer		: {type:Boolean, default : false},
			responsive	: Boolean,
			size		: {type : String, default : 'md'},
			thLength	: {type : Number, default : 50},
			id			: {type : String, default : '', required: true}
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey: '',
				sortOrders: sortOrders,
				searchData: '',
				itemIdsArray:[],
				
				selected: [],
			}
		},
		
		methods: {
			
			toast(toaster, content, append, variant, title) {
				// let content = toaster // `Toast ${this.counter} body content`    // `Toaster ${toaster}`
				this.$bvToast.toast(content, {
					title		: title,
					toaster		: toaster,
					solid		: true,
					appendToast	: append,
					variant		: variant
				})
			},
			
			doCopy (data) {
				let that = this
				this.$copyText(data).then(function (/* e */) {
					that.toast('b-toaster-bottom-left', 'Copied..', true, 'success', 'Success');
				}, function (/* e */) {
					that.toast('b-toaster-bottom-left', 'Can not copy..', true, 'warning', 'Warning');
				})
				
			},
			
			sortBy (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
		},
		watch: {
			itemIdsArray (val) {
				let that = this
				that.$emit( 'actionClick', val, 'selectItem' );
			},
			
		},
		computed: {
			filteredData () {
				
				var sortKey = this.sortKey
				var filterKey = this.searchData && this.searchData.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var dataItems = this.dataItems
				if (filterKey) {
					dataItems = dataItems.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					dataItems = dataItems.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return dataItems
			}
		},
		
	}
</script>

<style lang="scss">
	.custom-table {
		width: 100%;
		overflow-x: auto;
	}
	
	.tbl-header, .tbl-content {
		width: fit-content;
		table{
			table-layout: fixed;
			word-break: break-word;
		}
	}
	
	.tbl-header {
		border-top: 1px solid rgba(0, 0, 0, 0.0625);
		border-right: 1px solid rgba(0, 0, 0, 0.0625);
		border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
	}
	
	.pR-8 {
		padding-right: 8px;
	}
	
	.tbl-content{
		max-height:400px;
		/* overflow-x:auto; */
		margin-top: -1px;
		overflow-y: scroll;
	}

	/* for custom scrollbar for webkit browser */
	
	::-webkit-scrollbar {
		width: 6px;
	} 
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
	} 
	::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
	}
	
	
	
	
	.dataTables-grid {
		tbody {
			font-size: 12px;
			tr {
				td {
					vertical-align: inherit;
				}
			}
		}
		&.dT-sm {
			thead th, tfoot th {
				padding: 10px 10px;
				border-top-width: 0px;
				border-bottom-width: 0px;
				border-left: 1px solid rgba(0, 0, 0, 0.0625);
				text-transform: capitalize;
			}
			tbody {
				td {
					padding: 6px 10px;
				}
			}
			
		}
		&.dT-striped tbody tr:nth-of-type(odd) {
			background-color: #fafafa;
		}
		
		&.dT-hover tbody tr:hover {
			color: #212529;
			background-color: #f5f5f5;
		}
		
	}
	.dataTables-responsive {
		overflow-y: auto;
		height: 460px;
	}
</style>
