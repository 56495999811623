<template>
	<div class="dropdown-md" ref="closepanel">
		<div v-on:click="ToggleDropdown()" class="btn btn-link text-decoration-none">
			<flag :iso="userLanguage.flag" v-bind:squared=false /> {{ userLanguage.title }} <i class="fa fa-caret-down"></i>
		</div>
		<transition name="fade">
			<ul class="dropdown-ul-md" v-if="dropdown">
				<li v-for="entry in languages" :key="entry.title" @click="changeLocale(entry)">
					<flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
				</li>
			</ul>
		</transition>
	</div>
	
	<!--center>
		<div class="dropdown-md">
			<button v-on:click="ToggleDropdown()">CLICK</button>
			<transition name="fade">
				<ul class="dropdown-ul-md" v-if="dropdown">
					<li><a href="#">Lorem Lorem Lorem</a></li>
				</ul>
			</transition>
		</div>
	</center-->
	
	
</template>
<script>
	import i18n from '@/config/i18n';
	
	export default {
		name: 'Translate',
		data() {
			return {
				//
				userLanguage: {
					title	: 'English',
					flag	: 'us',
				},
				languages: [{
					flag: 'us',
					language: 'en',
					title: 'English'
				}, {
					flag: 'es',
					language: 'es',
					title: 'Español'
				}],
				dropdown: false,
			}
		},
		computed : {
			
		},
		methods: {
			ToggleDropdown() {
				document.addEventListener('click', this.documentClick);
				this.dropdown = !this.dropdown // === true ? false : true
			},
			
			documentClick(e) {
				var el = this.$refs.closepanel,
					target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						this.dropdown = false
						document.removeEventListener('click', this.documentClick);
					}
				}
			},
			
			changeLocale(entry) {
				i18n.locale = entry.language
				
				this.userLanguage.title	= entry.title
				this.userLanguage.flag	= entry.flag
			}
		}
	}
</script>
<style lang="scss" scope>
	.dropdown-md {
		
		display: inline-block;
		vertical-align: middle;
		width: fit-content;
		position: relative;
		
		button {
			position: relative;
			flex: 1 1 auto;
		}
		
		.dropdown-ul-md {
			
			min-width: 10rem;
			margin-top: 2px;
			width: max-content;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
			-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
			padding: 10px 0px;
			
			position: absolute;
			z-index: 99;
			
			font-size: 1rem;
			background-color: #fff;
			background-clip: padding-box;
			
			border: 1px solid rgba(0,0,0,.15);
			border-radius: .25rem;
			right: 0;
			text-align: left;
			list-style: none;
			
			li {
				padding: 2px 14px; // .25rem 1.5rem;
				cursor: pointer;
				a {
					color: #212529;
				}
				&.active, &:active {
					color: #fff;
					background-color: #007bff;
				}
				
				&:focus, &:hover {
					color: #16181b;
					background-color: #f5f5f5; // #f8f9fa
				}
				
			}
			
		}
	}
	
</style>