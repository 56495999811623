<template>
	<div>
		
		<div class="loader-chase" v-show="LoadingAction">
			<div class="sk-chase">
				<div class="sk-chase-dot"></div>
				<div class="sk-chase-dot"></div>
				<div class="sk-chase-dot"></div>
				<div class="sk-chase-dot"></div>
				<div class="sk-chase-dot"></div>
				<div class="sk-chase-dot"></div>
			</div>
			<div>Loading ...</div>
		</div>
		
	</div>
</template>
<script>
	
	export default {
		name: 'Loading',
		props: {
			
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed : {
			LoadingAction() {
				return this.$store.state.loading
			}
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	.loader-chase {
		position: fixed;
		top: 0px;
		left: 0px;
		background-color: #ffffffd4;
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100vh;
		z-index: 9999;
	}
	
	.sk-chase {
		width: 40px;
		height: 40px;
		position: relative;
		animation: sk-chase 2.5s infinite linear both;
		margin-right: 1em;
	}
	
	.sk-chase-dot {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0; 
		animation: sk-chase-dot 2.0s infinite ease-in-out both; 
	}
	
	.sk-chase-dot:before {
		content: '';
		display: block;
		width: 25%;
		height: 25%;
		background-color: #212121;
		border-radius: 100%;
		animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
	}
	
	.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
	.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
	.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
	.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
	.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
	.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
	.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
	.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
	.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
	.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
	.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
	.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
	
	@keyframes sk-chase {
		100% { transform: rotate(360deg); }
	}
	
	@keyframes sk-chase-dot {
		80%, 100% { transform: rotate(360deg); } 
	}

	@keyframes sk-chase-dot-before {
		50% {
			transform: scale(0.4); 
		} 100%, 0% {
			transform: scale(1.0); 
		} 
	}
</style>
