<template>
	<div class="custom-tabs">
		<ul :class="['nav nav-tabs row no-gutters', fill ? 'nav-fill' : '']">
			<template v-for="(tab, id) in Items">
				<li class="col-12 col-sm-6 col-md text-center nav-item" v-bind:key="`tab-${id}`" @click="toggleTabs(tab, tab.id)" v-if="isIsset(tab.enable)"><!--tab.enable-->
					<span :class="['nav-link cur-p', CurrentTab == tab.id ? 'active' : '']">
						<i v-if="tab.icon != null && tab.icon != ''" :class="tab.icon"></i> {{ tab.label }} <span v-if="tab.count != ''">({{ tab.count }})</span>
					</span>
				</li>
			</template>
		</ul>
		
		<!--div class="nav nav-tabs nav-fill status-tabs">
			<span v-for="(tab, id) in tabsStatus" v-bind:key="`tab-${id}`" :class="['nav-item nav-link c-pointer', tab.enable ? 'active' : '']" @click="toggleStatus(tab)">
				{{ tab.title }} ({{ tab.count }})
			</span>
		</div-->
		
	</div>
</template>
<script>
	
	export default {
		name: 'Tabs',
		props: {
			Items	: Array,
			fill	: { type: Boolean, default: false },
			current : {  } // type: Number , default: 0	 
			// Items  : {type: Array, default: ''},
		},
		components: {
			
		},
		data() {
			return {
				CurrentTab: ''
			}
		},
		mounted () {
			this.CurrentTab = this.current
		},
		computed : {
			
		},
		methods: {
			
			toggleTabs(tab, id) {
				let that = this
				if(that.CurrentTab != tab.id) {
					that.CurrentTab = tab.id
					that.$emit('change', tab, id)
				}
			},
			
		},
		watch: {
			current(val) {
				let that = this
				that.CurrentTab = val
				// if( val == 1 ) {
					// that.CurrentTab = 1
				// }
			}
		}
	}
</script>
<style lang="scss" scope>
	.custom-tabs {
		.nav-tabs {
			.nav-link {
				padding: 10px 1rem;
				&:hover, &:focus {
					border-color: #fff #fff #dee2e6;
					color: #495057;
					background-color: #f8fafc;
				}
				&.active {
					font-weight: 500;
					&:hover {
						border-color: #dee2e6 #dee2e6 #f8fafc !important;
					}
				}
			}
		}
	}
</style>
