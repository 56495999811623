<template>
	<div :class="['md-stepper-horizontal', color]">
		<div :class="['md-step', stepIn == 'step1' ? 'active editable' : 'active done']"><!--  done -->
			<div class="md-step-circle"><span>1</span></div>
			<div class="md-step-title">{{ steps[0] }}</div>
			<div class="md-step-bar-left"></div>
			<div class="md-step-bar-right"></div>
		</div>
		<div :class="['md-step', stepIn == 'step2' ? 'active editable' : ( stepIn == 'step3' ? 'active done' : '' ) ]"><!--  active editable -->
			<div class="md-step-circle"><span>2</span></div>
			<div class="md-step-title">{{ steps[1] }}</div>
			<div class="md-step-bar-left"></div>
			<div class="md-step-bar-right"></div>
		</div>
		<div :class="['md-step', stepIn == 'step3' ? 'active editable' : '']"><!--  done -->
			<div class="md-step-circle"><span>3</span></div>
			<div class="md-step-title">{{ steps[2] }}</div>
			<div class="md-step-bar-left"></div>
			<div class="md-step-bar-right"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Stepper',
		props: {
			stepIn	: String,
			steps 	: Array,
			color	: String
		},
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		methods: {
			
		}
	}
</script>


<style lang="scss" scope>
	
	.md-stepper-horizontal {
		display:table;
		width:100%;
		margin:0 auto;
		background-color:#FFFFFF;
		
		.md-step {
			display:table-cell;
			position:relative;
			padding:22px 24px 0px 24px;
		}
		.md-step:last-child:active {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.md-step:hover .md-step-circle {
			background-color:#757575;
		}
		
		.md-step:first-child .md-step-bar-left, .md-step:last-child .md-step-bar-right {
			display:none;
		}
		
		.md-step .md-step-circle {
			width:30px;
			height:30px;
			margin:0 auto;
			background-color:#999999;
			border-radius: 50%;
			text-align: center;
			line-height:30px;
			font-size: 14px;
			font-weight: 600;
			color:#FFFFFF;
		}
		
		&.green .md-step.active .md-step-circle {
			background-color:#1c8b55;
		}
		&.orange .md-step.active .md-step-circle {
			background-color:#F96302;
		}
		&.blue .md-step.active .md-step-circle {
			background-color:#2196f3;
		}
		.md-step.active .md-step-circle {
			background-color: rgb(33,150,243);
		}
		
		.md-step.done .md-step-circle:before {
			font-family:'FontAwesome';
			font-weight:100;
			content: "\f00c";
		}
		
	}
	
	.md-stepper-horizontal .md-step.done .md-step-circle *,
	.md-stepper-horizontal .md-step.editable .md-step-circle * {
		display:none;
	}
	.md-stepper-horizontal .md-step.editable .md-step-circle {
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}
	.md-stepper-horizontal .md-step.editable .md-step-circle:before {
		font-family:'FontAwesome';
		font-weight:100;
		content: "\f040";
	}
	.md-stepper-horizontal .md-step .md-step-title {
		margin-top:14px;
		font-size:14px;
		font-weight:600;
	}
	.md-stepper-horizontal .md-step .md-step-title,
	.md-stepper-horizontal .md-step .md-step-optional {
		text-align: center;
		color:rgba(0,0,0,.26);
	}
	.md-stepper-horizontal .md-step.active .md-step-title {
		font-weight: 600;
		color:rgba(0,0,0,.87);
	}
	.md-stepper-horizontal .md-step.active.done .md-step-title,
	.md-stepper-horizontal .md-step.active.editable .md-step-title {
		font-weight:600;
	}
	.md-stepper-horizontal .md-step .md-step-optional {
		font-size:12px;
	}
	.md-stepper-horizontal .md-step.active .md-step-optional {
		color:rgba(0,0,0,.54);
	}
	.md-stepper-horizontal .md-step .md-step-bar-left,
	.md-stepper-horizontal .md-step .md-step-bar-right {
		position:absolute;
		top:36px;
		height:1px;
		border-top:1px solid #bdbdbd;
	}
	.md-stepper-horizontal .md-step .md-step-bar-right {
		right:0;
		left:50%;
		margin-left:20px;
	}
	.md-stepper-horizontal .md-step .md-step-bar-left {
		left:0;
		right:50%;
		margin-right:20px;
	}
	
</style>