<template>
	<div class="material-multiselect" ref="closepanel" :style="{width:width}">
		<div class="multiselect-button" @click="toggle()" :style="{width:width}">
			<i :class="[faIcon]" v-if="faIcon != ''"></i> <input :value="buttonText" readonly ref="infoBox" :style="{width:'calc(' + width + ' - 35px)'}" />
			<!-- onkeypress="this.style.width = ((this.value.length + 1) * 8) + 'px';" -->
			<i :class="['ti-icon', panelOpen ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down']" v-if="caret"></i>
			<!--i :class="['fa', 'fa-close']" v-if="selected.length > 0" @click="clear"></i--> <!--  button is-link  role="button" -->
		</div>
		<transition name="slide-fade">
			<div class="multiselect-option" v-show="panelOpen" :style="{minWidth:width}">
				<select-list ref="selectList" :options="options" :selected="selected" @change="onChange" :defaultSelected="defaultSelected" :selectAllCheck="selectAllCheck" :titleOption="titleOption"></select-list>
				<div class="footer-items" @click="panelOpen = false">
					<slot name="footer"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
	
	import selectList from './select-list.vue'
	export default {
		
		components: {
			selectList
		},
		data() {
			return {
				panelOpen	  : false,
				buttonText	  : this.label,
				selectData	  : false,
				selectAllCheck: false,
				selectedTitle : []
			};
		},
		props: {
			'caret'		: Boolean,
			'options'	: Array,
			'selected'	: Array,
			'label'		: String,
			'placeholderType': String,
			'placeholder': String,
			'faIcon'	:  { type : String, default : "" },
			'defaultSelected': { type : String, default : "" }, // all
			'selectAll'	: Boolean,
			'titleOption': String,
			width	  	: { type : String, default : "200px" },
		},
		
		mounted() {
			let that = this
			
			if( typeof that.selectAll != 'undefined' ) {
				that.selectAllCheck = that.selectAll
			} else {
				that.selectAllCheck = false
			}
			
			that.options.forEach( function(item) {
				if( that.defaultSelected == 'all' ) {
					if ( !that.selected.includes(item.id) ) {
						that.selected.push(item.id)
					}
				}
				that.selectedTitle[item.id] = that.isIsset(item.title) ? item.title : item.option // item.option
			} )
			
			if( that.defaultSelected == 'all' ) {
				that.onChange()
			}
			
		},
		
		methods: {
			// ---------------------------
			clear() {
				let that = this
				Object.keys(that.selected).forEach(function (/* item */) {
					that.selected.pop();
				});
				this.panelOpen = !this.panelOpen;
				this.onChange()
			},
			// ---------------------------
			
			toggle() {
				/* let $event = event */
				document.addEventListener('click', this.documentClick);
				this.panelOpen = !this.panelOpen;
			},
			
			documentClick(e) {
				var el = this.$refs.closepanel,
					target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						this.panelOpen = false
						document.removeEventListener('click', this.documentClick);
					}
				}
			},
			
			onChange(action, element) {
				let that = this
				if( action == 'selectall' ) {
					if(element) {
						that.options.forEach( function(item) {
							if( !that.selected.includes(item.id) ) {
								that.selected.push(item.id);
							}
						} )
					} else {
						that.options.forEach( function(item) {
							that.selected.splice(that.selected.indexOf(item.id), 1);
						} )
					}
				}
				
				that.$emit('change', action == 'element' ? element : that.selected )
			}
		},
		
		watch: {
			
			options: {
				handler: function (val) { // , oldVal
					let that = this
					val.forEach( function(item) {
						if( that.defaultSelected == 'all' ) {
							if ( !that.selected.includes(item.id) ) {
								that.selected.push(item.id)
							}
						}
						that.selectedTitle[item.id] = that.isIsset(item.title) ? item.title : item.option // item.option
					} )
					if( that.defaultSelected == 'all' ) {
						that.onChange()
					}
				},
				deep: true
			},
			
			
			selected: {
				handler: function (val) { // , oldVal function (val) {
					let that = this, title = ''
					
					if( this.selectAllCheck && val.length == that.options.length && this.placeholderType == 'count' ) {
						// this.buttonText = 'All ' + this.placeholder
						this.buttonText = val.length + ' items'
					} else if( this.placeholderType == 'title' ) {
						
						if( val.length == 0 ) {
							title = this.label
						} else {
							let placeholder = []
							val.forEach( function(item) {
								placeholder.push( that.selectedTitle[item] )
							} );
							placeholder = placeholder.join(', ')
							title 		= placeholder
							
							var infoBox = this.$refs.infoBox
							var width   = (infoBox.clientWidth + 52)
							
							var calculSize 	= (width * title.length) / ((title.length + 1) * 8)
							var lastValue 	=  calculSize.toString()
								lastValue 	=  parseInt( lastValue )
							// ((this.value.length + 1) * 8) + 'px';
								title		= title.length >= lastValue ? title.substring( 0, lastValue - 4 /* 2 */ ) + "..." : title
						}
						
						this.buttonText = title;
					} else {
						let title = val.length + ' Item selected'
						if( val.length > 1 ) {
							title = val.length + ' Items selected'
						} else if( val.length == 0 ) {
							title = this.label
						}
						this.buttonText = title;
					}
				},
				deep: true
			},
		},
	}
</script>
<style lang="scss">
	
	.material-multiselect {
		position: inherit;
		display: inline-block;
		div.multiselect-button {
			cursor: pointer;
			background-color: #fff;
			border: 0px;
			height: 40px;
			display: flex;
			color: #8c8b8b;
			padding: 5px 10px;
			border: 1px solid #ced4da;
			border-radius: 3px;
			position: relative;
			
			&:hover {
				box-shadow: 0px 2px 4px #eee;
			}
			
			>input {
				font-size: 13px;
				margin-top: auto;
				margin-bottom: auto;
				padding-left: 5px;
				padding-right: 5px;
				cursor: pointer;
				color: #7a7c7c;
				border:0;
				/* width: 88px; */
			}
			
			.ti-icon {
				margin-top: auto;
				margin-bottom: auto;
			}
			
			.fa-close {
				position: relative;
				right: 0px;
				top: 2px;
			}
			
			.ti-arrow-circle-up, .ti-arrow-circle-down {
				color: #a5a5a5 !important;
				position: absolute;
				right: 12px;
				top: 13px;
			}
			
		}
		
		.select-list-actions {
			margin-bottom: 0px;
			border-bottom: 1px solid #e2e2e2;
			
			input[type="text"].multiselect-search {
				font-size: 13px;
				/* width: 100%; */
				border: 0px;
				height: 35px !important;
			}
			
		}
		
		.select-list {
			padding-top: 0px;
			flex-wrap: wrap;
			max-height: 32vh; /* 35vh */
			overflow-y: auto;
			text-align: left;
		}
		.select-item {
			flex-basis: 20%;
			margin-bottom: 1%;
			justify-content: flex-start;
		}
		.toggle-button {
			background: none;
			color: #676d73;
			border: none;
			padding: 5px 8px 5px 4px;
			position: relative;
			margin-left: 0 !important;
			transition: font-weight .1s ease-in;
			font-size: 13px;
			/* text-transform: capitalize; */
			
			b, strong {
				font-weight: 500;
			}
		}
		
		.toggle-button:hover {
			background: #f3f3f3;
			cursor: pointer;
		}
		.toggle-button:focus {
			box-shadow: none;
		}
		
		.toggle-button.is-selected {
			color: #676d73;
		}
		.toggle-button.is-selected .fa-select-toggle {
			opacity: 1;
		}
		.toggle-button .fa-select-toggle {
			opacity: 0;
		}
		
		
		.toggle-button .fa {
			font-size: 14px;
			padding: 0px 0px 0px 6px;
			width: 26px;
			transition: all .1s ease-in;
		}
		
		.multiselect-option {
			
			position: absolute;
			z-index: 99;
			box-shadow: 0px 2px 4px #eee;
			background-color: #fff;
			border: 1px solid #ced4da;
			
			margin-top: 2px;
			border-radius: 2px 2px 1px 1px;
			
			.no-items {
				font-size: 14px;
				padding: 8px 2px;
				text-align: center;
			}
			
			.option-drop {
				font-weight: 600;
				padding: 8px 8px 0px 8px !important;
				cursor: context-menu;
				font-size: 14px !important;
			}
			
			.footer-items {
				>* {
					/* border-top: 1px solid #eeeeee; */
					padding: 10px 12px;
					display: block;
					color: #4e4e4e;
					font-size: 13px;
				}
				a:hover, span:hover {
					background-color: #eee;
				}
			}
		}
		
		.button.is-link {
			color: #6c757d;
			width: 14%;
			padding-bottom: 4px;
		}
		
		.slide-fade-enter-active {
			transition: all .3s ease;
		}

		.slide-fade-leave-active {
			transition: all .1s ease;
		}

		.slide-fade-enter, .slide-fade-leave-active {
			transform: translateY(20px);
			opacity: 0;
		}
		
	}
	
</style>
