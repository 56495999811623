<template>
	<div class="strap_dataTables_paginate paging_simple_numbers">
		<!--span class="caption">
			Showing 1 to 10 of 57 entries
		</span-->
		Page: <input type="number" placeholder="Page" class="current-page hide-spin" v-model.number="current_page" @keyup="changePage" />
		<!--select><option>items per page</option></select-->
		<a :class="['paginate_button first', currentPage == 1 ? 'disabled' : '' ]" @click="onClickFirstPage">
			<i class="fa fa-angle-double-left"></i>
		</a>
		<a :class="['paginate_button previous', currentPage == 1 ? 'disabled' : '']" @click="onClickPreviousPage">
			<i class="fa fa-angle-left"></i>
		</a>
		<template v-for="(page, key) in pages">
			<a class="paginate_button" v-bind:key="`page-${key}`" @click="onClickPage(page.number)" :class="{ active: isPageActive(page.number) }"
				:disabled="page.isDisabled"> {{page.number }}</a>
		</template>
		<a :class="['paginate_button next', currentPage == lastPage ? 'disabled' : '']" @click="onClickNextPage">
			<i class="fa fa-angle-right"></i>
		</a>
		<a :class="['paginate_button last', currentPage == lastPage ? 'disabled' : '']" @click="onClickLastPage">
			<i class="fa fa-angle-double-right"></i>
		</a>
	</div>
</template>

<script>
export default {
	name: 'pagination',
	props: {
		currentPage 	: { type : Number, required: true },
		lastPage		: { type : Number, required: true },
		maxVisiblePages	: { type : Number, required: true, default : 3 }
	},
	data() {
		return {
			current_page: null,
		}
	},
	methods: {
		changePage() {
			let current_page = this.current_page
			if( !isNaN(parseFloat(current_page)) && isFinite(current_page) && current_page != '' ) {
				if ( current_page > 0 && current_page <= this.lastPage && this.current_page != this.currentPage ) {
					this.$emit('page-changed', current_page)
				}
			}
		},
		onClickPage(page) {
			if( this.current_page != page ) {
				this.current_page = page
				this.$emit('page-changed', this.current_page)
			}
		},
		onClickNextPage() {
			if( this.currentPage < this.lastPage ) {
				this.current_page = this.currentPage + 1
				this.$emit('page-changed', this.current_page)
			}
		},
		onClickPreviousPage() {
			if( this.currentPage > 1 ) {
				this.current_page = this.currentPage - 1
				this.$emit('page-changed', this.current_page)
			}
		},
		onClickLastPage() {
			if( this.currentPage < this.lastPage ) {
				this.current_page = this.lastPage
				this.$emit('page-changed', this.current_page)
			}
		},
		onClickFirstPage() {
			if( this.currentPage > 1 ) {
				this.current_page = 1
				this.$emit('page-changed', this.current_page)
			}
		},
		isPageActive(page) {
			return this.currentPage === page;
		}
	},
	computed: {
		pages() {
			let that = this, p = [], i = 1
			if(this.currentPage === 1){
				let maxVisiblePages = that.lastPage > 2 ? that.maxVisiblePages : that.lastPage
				for(i=1;i<=maxVisiblePages;i++) {
					p.push({'number': i, 'isDisabled': false })
				}
				// return [{'number': 1, 'isDisabled': true },{'number': 2, 'isDisabled': false  }, {'number': 3, 'isDisabled': false }];
			}
			else if(this.currentPage == this.lastPage){
				let maxVisiblePages = that.lastPage > 2 ? that.maxVisiblePages : that.lastPage
				for(i;i<=maxVisiblePages;i++) {
					p.push({'number': that.lastPage - ( maxVisiblePages - i ), 'isDisabled': false })
				}
				// return [{'number': this.lastPage - 2, 'isDisabled': true },{'number': this.lastPage - 1, 'isDisabled': false  }, {'number': this.lastPage, 'isDisabled': false }];
			} else {
				/* let maxVisiblePages = that.lastPage > 2 ? that.maxVisiblePages : that.lastPage, increment = 0
				for(i;i<=maxVisiblePages;i++) {
					
					if( i * 2 > maxVisiblePages ) {
						increment++
						p.push({'number': that.currentPage + increment, 'isDisabled': false })
					} else if( i * 2 < maxVisiblePages ) {
						p.push({'number': that.currentPage - (maxVisiblePages - i), 'isDisabled': false })
					} else {
						p.push({'number': that.currentPage, 'isDisabled': false })
					}
					
				} */
				p.push({'number': this.currentPage - 1	, 'isDisabled': false});
				p.push({'number': this.currentPage		, 'isDisabled': true});
				p.push({'number': this.currentPage + 1	, 'isDisabled': false});
			}
			return p
		}
	},
	mounted() {
		this.current_page = this.currentPage
	}
}
</script>

<style lang="scss">
	.strap_dataTables_paginate {
		text-align: right;
		margin: 1em 0em;
		.paginate_button {
			box-sizing: border-box;
			display	: inline-block;
			padding	: 8px 14px;
			font-size: 12px;
			margin-left: 2px;
			text-align: center;
			text-decoration: none !important;
			cursor	: pointer;
			color	: #333 !important;
			border	: 1px solid #eee;
			border-radius: 2px;
			background-color: #fff;
			&.disabled {
				background-color: #eee;
				cursor: default;
				opacity: 0.8;
				&:hover {
					border	: 1px solid #eee;
				}
			}
			&:hover {
				border	: 1px solid #979797;
				box-shadow: 0px 0px 4px #eee; /* #979797   #222d32 */
			}
			&.active {
				background-color: #343a40;
				border-color: #343a40;
				color: #ffffff !important;
			}
		}
		.paginate_button:current {
			border	: 1px solid #979797;
		}
		
		input.current-page {
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			height: 36px;
			display: inline-block;
			vertical-align: bottom;
			width: 62px;
			padding: 0.375rem 0.75rem;
			margin-right: 4px;
			
			color: #495057;
			
		}
		
		.caption {
			color: #6c757d;
			float: left;
			margin: 4px 0px;
		}
		
	}
	
	/* Chrome, Safari, Edge, Opera */
	input.hide-spin::-webkit-outer-spin-button,
	input.hide-spin::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	
	/* Firefox */
	input.hide-spin[type=number] {
		-moz-appearance: textfield;
	}
	
	
	
</style>