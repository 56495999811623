<template>
	<div>
		<div id="paypal-button-container"></div>
	</div>
</template>
<script>
	
	export default {
		name: 'paypal-checkout',
		props: {
			Amount	: { default: 25.00 }, // Type: Number, 
			Currency: { default: 'USD' }
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		mounted() {
			this.PayAction()
		},
		watch: {
			
		},
		methods: {
			PayAction() {
				let that = this
				that.Amount = parseFloat(that.Amount) > 0 ? that.Amount : 25
				window.paypal.Button.render({
					env		: 'production', // Optional: specify 'sandbox' environment     , production
					style	: {
						layout: 'vertical', label: 'pay', shape: 'rect'
						// fundingicons: true,
					},
					funding	: {
						allowed		: [ window.paypal.FUNDING.CARD ], // , window.paypal.FUNDING.VENMO, window.paypal.FUNDING.ELV
						disallowed	: [ window.paypal.FUNDING.CREDIT ]
					},
					client	: {
						sandbox		: 'AT7czlJ59NgblgKTJHAtTMh7UK3APuxBxBvEx_QAlsFEe_y_Yv-T00Sqa42XLBZ34ECbi_a-DJKRuYIa',
						production	: 'AUF3DKpTf216eUrpTfGwAKkxscVBvzSVSbAzSaI5I3obZq_uGlgUKABp-es7sVOtj5Hs32OSpReaW0SU'
					},
					commit	: true, // Optional: show a 'Pay Now' button in the checkout flow
					payment	: function (data, actions) {
						// that.$emit( 'onAuthorize', JSON.parse('{"id":"PAYID-L6FOWDA67J62047FG328250X","intent":"sale","state":"approved","cart":"4HS871271C7246634","create_time":"2020-10-17T13:00:59Z","payer":{"payment_method":"paypal","status":"VERIFIED","payer_info":{"email":"mouadsah-buyer@gmail.com","first_name":"test","middle_name":"test","last_name":"buyer","payer_id":"5NZG3HWWSGPR2","country_code":"MA"}},"transactions":[{"amount":{"total":"0.60","currency":"USD","details":{"subtotal":"0.60","shipping":"0.00","handling_fee":"0.00","insurance":"0.00","shipping_discount":"0.00"}},"item_list":{},"related_resources":[{"sale":{"id":"1P948173DA719350A","state":"completed","payment_mode":"INSTANT_TRANSFER","protection_eligibility":"ELIGIBLE","parent_payment":"PAYID-L6FOWDA67J62047FG328250X","create_time":"2020-10-17T13:01:55Z","update_time":"2020-10-17T13:01:55Z","amount":{"total":"0.60","currency":"USD","details":{"subtotal":"0.60","shipping":"0.00","handling_fee":"0.00","insurance":"0.00","shipping_discount":"0.00"}}}}]}]}') )
						return actions.payment.create({
							payment: { 
								transactions: [{
									amount: {
										total: that.Amount, currency: that.Currency
									},
								}],
							},
							
							experience: {
								input_fields: {
									no_shipping: 1
								}
							}
						});
					},
					onAuthorize: function (data, actions) {
						// Optional: display a confirmation page here
						return actions.payment.execute().then(function (response) {
							// Show a success page to the buyer
							that.$emit( 'onAuthorize', response )
						});
					},
					onCancel: function (data, actions) {
						// Show a cancel page or return to cart
						that.$emit( 'onCancel', data, actions )
					},
					onError: function (err) {
						// Show a cancel page or return to cart
						that.$emit( 'onError', err )
					}
				}, '#paypal-button-container');
			}
		}
	}
</script>
<style lang="scss">
	/* Media query for mobile viewport */
	@media screen and (max-width: 400px) {
		#paypal-button-container {
			width: 100%;
		}
	}
	
	/* Media query for desktop viewport */
	@media screen and (min-width: 400px) {
		#paypal-button-container {
			width: 250px;
		}
	}
	
</style>
